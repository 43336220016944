import { createSlice } from '@reduxjs/toolkit'

export const extractorSlice = createSlice({
  name: 'extractor',
  initialState: {
    canExtract: true,
    extractionStart: 0,
  },
  reducers: {
    enable: (state) => {
      state.canExtract = true
    },
    disable: (state) => {
      state.canExtract = false
    },
    setProgress: (state, action) => {
      state.extractionStart = action.payload
    },
  },
})

export const { enable, disable, setProgress } = extractorSlice.actions

export default extractorSlice.reducer
