import { configureStore } from '@reduxjs/toolkit'

import extractorReducer from '../features/extractor/extractorSlice'

const store = configureStore({
  reducer: {
    extractor: extractorReducer,
  },
})

export default store
